import React, { useEffect } from "react";

import NavBar from "./components/Navbar";
import Main from "./components/Main";
import { AppContext } from "./libs/contextLib";
import { useAuth } from "./libs/authLib";

import "./App.css";

const App = () => {
  const auth = useAuth()
  useEffect(() => {
    auth.onLoad()
  }, [auth]);
  return (
    <div className="App container py-3">
      <AppContext.Provider value={{auth}}>
        <NavBar auth={auth}/>
        <Main/>
      </AppContext.Provider>
    </div>
  );
}

export default App;

