import * as React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { onError } from "./errorLib";


function useAuth() {
    const navigate = useNavigate();
  const [authed, setAuthed] = React.useState(false);
  const [newUser, setNewUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(true)

  return {
    authed,
    newUser,
    isLoading,
    isAuthenticating,
    async handleLogout() {
      await Auth.signOut();
    
      setAuthed(false);
      navigate("/login");
    },
    async onLoad() {
      try {
        await Auth.currentSession();
        setAuthed(true);
        setIsAuthenticating(false)
      }
      catch(e) {
        setAuthed(false);
        setIsAuthenticating(false)
        console.log(e)
        if (e !== 'No current user') {
          alert(e);
        }
      }
    },
    async login(email,password) {
    
      try {
        await Auth.signIn(email, password);
        setAuthed(true);
      } catch (e) {
        console.log(e)
          onError(e.message);
          setIsLoading(false);
      }
      // setIsAuthenticating(false)
    },
    async signup(email, pswd, ) {
      
      setIsLoading(true);
    
      try {
        const usr = await Auth.signUp({
          username: email,
          password: pswd
        });
        setIsLoading(false);
        setNewUser(usr);
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    },
    async signupConfirm(username, fields) {
    
      setIsLoading(true);
    
      try {
        await Auth.confirmSignUp(username, fields.confirmationCode);
        await Auth.signIn(fields.email, fields.password);
    
        setAuthed(true);
        
        navigate("/");
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    }
  };
}


export {useAuth}