import React from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components"

import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
import LoaderButton from "../Utils/LoaderButton";


export default function Login() {
  const { auth } = useAppContext();
  const {isLoading, login} = auth
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  const validateForm = () => {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  const handleSubmit = (event) =>{
    event.preventDefault();
    login(fields.email,fields.password)
  }
  

  return (
    <Wrapper className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @media  ( min-width: 640px) {
    padding: 60px 0;
    form{
      margin: 0 auto;
      max-width: 320px;
    }
  }
`


