import React from 'react'

import {NavLink} from "react-router-dom"
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const NavBar = ({auth}) => {
  return (
    <>
         <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
        <NavLink to="/">
          <Navbar.Brand className="font-weight-bold text-muted">
            Donfred
          </Navbar.Brand>
        </NavLink>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
          {auth.authed ? (
            <Nav.Link onClick={auth.handleLogout}>Logout</Nav.Link>
          ) : (
            <>
              <Nav.Item>
                <Nav.Link href="/signup" >Signup</Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href="/login">Login</Nav.Link>
              </Nav.Item>
            </>
)}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default NavBar