/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cognito_identity_pool_id": "eu-west-3:59a6d402-20e4-4827-ae8a-3d5f29e7ca88",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_96ayDMTr7",
    "aws_user_pools_web_client_id": "5rr02vksclcsuk113p7h95ve81",
    "oauth": {}
};


export default awsmobile;
