

import React from 'react';
import styled from "styled-components"
import { Route,Routes } from "react-router-dom";

import Upload from "./Upload";
import Profile from "./Profile";
import Sender from "./MailSender"
import NotFound from "./NotFound";
import Signup from "./authentication/Signup";
import Login from "./authentication/Login";
import AuthenticatedRoute from "./authentication/AuthenticatedRoute";
import UnauthenticatedRoute from "./authentication/UnauthenticatedRoute";



function Main() {
  
  return (
      <React.Fragment>
        <Wrapper>
            <Routes>
                <Route exact path="/" element={<AuthenticatedRoute ><Upload /></AuthenticatedRoute>}/>
                <Route exact path="/profile" element={<AuthenticatedRoute ><Profile /></AuthenticatedRoute>}/>
                <Route exact path="/mailsender" element={<AuthenticatedRoute><Sender /></AuthenticatedRoute>}/>
                <Route exact path="/login" element={<UnauthenticatedRoute><Login /></UnauthenticatedRoute>}/>
                <Route exact path="/signup" element={<UnauthenticatedRoute><Signup /></UnauthenticatedRoute>}/>
                <Route path="*" element={<NotFound />}/>
            </Routes>
        </Wrapper>
        
    </React.Fragment>
  );
  
} 
const Wrapper = styled.div`
  background: #f8f8f8;
`
export default Main;