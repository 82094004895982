import React from "react";
import styled from "styled-components"

export default function NotFound() {
  return (
    <Wrapper className="NotFound text-center">
      <h3>Sorry, page not found!</h3>
    </Wrapper>
  );
}
const Wrapper = styled.div`
    padding-top: 100px;
`