import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";

const AuthenticatedRoute = (props) => {
  const { children, ...rest } = props;
//   const { pathname, search } = useLocation();
  const { auth } = useAppContext();
  console.log(rest)
  if(auth.isAuthenticating){
    return <h1>Loading</h1>
  }
  if(auth.authed){
    return children
  }
  return  <Navigate to={`/login`} replace />
    
}
export default AuthenticatedRoute