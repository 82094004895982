import React, { useState, useEffect } from 'react';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import { useAppContext } from "../../libs/contextLib";
import AWS from 'aws-sdk';
import styled from "styled-components";
import { Table } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa'


const s3 = new AWS.S3();
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
const params = {
  Bucket: 'fileuploader-react-bucket',
  Delimiter: ''
};
const Tr = styled.tr`
    left: 0px;
    padding: 20px;
    transition: all 0.5s, background-color 0s;
    width: calc(100% - 40px);
`
const FileRow = (props) => {
      return (
        <Tr>
          <td>{props.file.Key}</td>
          <td>{props.file.StorageClass}</td>
          <td>{formatBytes(props.file.Size) } </td>
          <td><FaDownload onClick={()=>props.download(props.file.Key)}style={{cursor: "pointer"}}/></td>
        </Tr>
      );
  }
const Tbody = styled.tbody`
    margin: 20px;
    margin-top: 0px;
    overflow: hidden;
    position: relative;
`
  
const FileTable = (props) => {
    const rows = [];
    // console.log(props)
    props.files.forEach((file) => {
        if (file.Key.indexOf(props.filterText) === -1) {
          return;
        }
        rows.push(<FileRow file={file} key={file.Key} download={props.download} />);
    });
    console.log()
    if(!rows.length){
      return<h1>Loading</h1>
    }
    return(
      <Table striped bordered hover>
        <thead>
                 <tr>
                   <th>Name</th>
                    <th>Storage class</th>
                    <th>size</th>
                    <th>download</th>
                 </tr>
             </thead>
             <tbody>
             {rows}
             </tbody>
      </Table>
    )
  }
  
const SearchBar = ({filterText,onFilterTextInput}) => {

    const updateSearchInput = e => {
        onFilterTextInput(e.target.value);
    };
    return (
        <form>
            <input
                className="form-control"
                type="text"
                placeholder="Search..."
                value={filterText}
                onChange={updateSearchInput}
            />
        </form>
    );
}
  
const FilterableFileTable = () => {
    const [searchEntry, setSearchEntry] = useState("");
    const [listFiles, setListFiles] = useState([])
    const { isAuthenticated } = useAppContext();
    console.log(isAuthenticated)
    const handleFilterTextInput = (filterText) => {
        setSearchEntry(filterText);
    };
    const handleClick = (e) => {
      e.preventDefault();
    };
    const headParams = {
      Bucket: "fileuploader-react-bucket",
      Key: `onb.zip`,
    };
    const getHead = (bucketName, key)=> new AWS.S3.HeadObject({
      params: {
        Bucket: bucketName,
        Key: key
      }
    });
    const boom = () => {
      // console.log(s3.headObject())
      getHead("fileuploader-react-bucket","onb.zip").promise().then(
        function(data) {
          console.log(data)
        },
        function(err) {
          console.log(err)
          //return alert("There was an error uploading your photo: ", err.message);
        }
      )
      
    }
    const handleDownload = (key) => {
      const downParams = {
        Bucket: "fileuploader-react-bucket",
        Key: `${key}`,
      };
     
      function downloadBlob(blob, name = `${key}`) {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);
        // Create a link element
        const link = document.createElement('a');
        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = name;
        // Append link to the body
        document.body.appendChild(link);
        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
  
        // Remove link from body
        document.body.removeChild(link);
      }
      
      s3.getObject(downParams, (err, objectData) => {
        if (err) {
          console.log(err, err.stack);
        } else {
          s3.headObject(downParams,(errHead,headData) => {
            if(errHead){
              console.log(errHead, errHead.stack)
              // return
            }
            console.log(headData)
            let csvBlob = new Blob([objectData.Body.toString()], {
              type: 'text/csv;charset=utf-8;',
            });
            downloadBlob(csvBlob, `${key}`);
          })
          // console.log(objectData)
          
        }
      });
  
  }
    useEffect(() => {
        s3.listObjectsV2(params, (err, data) => {
          if (err) {
            console.log(err, err.stack);
          } else {
            setListFiles(data.Contents);
            console.log(data);
          }
        });
      }, []);
    
    return (
        <div>
            <h1>Mes Documents</h1>
            <SearchBar
                filterText={searchEntry}
                onFilterTextInput={handleFilterTextInput}
            />
            <FileTable
                files={listFiles}
                filterText={searchEntry}
                download={handleDownload}
            />
        </div>
    );
  }
  
  
  
  
  export default FilterableFileTable
