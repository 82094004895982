import React from "react"
import styled, {css} from "styled-components"
import axios  from "axios";

/** Components */


const Card = props => (
    <div className="card">
      {/*<div className="waves">
      </div>*/}
      {props.children}
    </div>
  );
  
const Form = props => (
        <form className="form">{props.children}</form>
);
  
const TextInput = props => (
    <div className="text-input">
        <label
            className={(props.focus || props.value !== '') ? 'label-focus' : ''}
            htmlFor={props.name}>{props.label}
        </label>
        <input
            className={(props.focus || props.value !== '') ? 'input-focus' : ''}
            type="text"
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            onInput={props.onInput}
            onFocus={props.onFocus}
            onBlur={props.onBlur} 
        />
    </div>
  );
const SelectInput = props => (
    <div className="text-input select-input">
        <label className={(props.focus || props.value !== '') ? 'label-focus' : ''}
                htmlFor={props.name}>{props.label}
        </label>
        <select value={props.value} className={(props.focus || props.value !== '') ? 'input-focus' : ''}onFocus={props.onFocus}
            onBlur={props.onBlur} name={props.name} onChange={props.onChange}>
          <option value="francais">Francais</option>
          <option value="anglais">Anglais</option>
        </select>
    </div>
)
const TextArea = props => (
    <div className="text-area">
        <label
            className={(props.focus || props.value !== '') ? 'label-focus' : ''}
            htmlFor={props.name}>{props.label}
        </label>
        <textarea
            className={(props.focus || props.value !== '') ? 'input-focus' : ''}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            onInput={props.onInput}
            onFocus={props.onFocus}
            onBlur={props.onBlur} 
        />
    </div>
);
  
const Button = props => (
    <button type="button" onClick={props.onClick}
        className="button">{props.children}
    </button>
);
  const MAIL_API = "https://tljx0z9fia.execute-api.eu-west-3.amazonaws.com/default/emailSender"
  /** Root Component */
class Sender extends React.Component {
    constructor() {
      super();
      this.state = {
        name: {
          name: 'name',
          label: 'Nom du recruteur',
          value: '',
          focus: false,
        },
        company_name: {
            name: 'company_name',
            label: 'Nom de la societe',
            value: '',
            focus: false,
          },
        role: {
            name: 'role',
            label: 'Titre du poste',
            value: '',
            focus: false,
          },
        email: {
          name: 'email',
          label: 'Email du recruteur',
          value: '',
          focus: false,
        },
      
        langue: {
            name: 'langue',
            label: 'Langue',
            value: '',
            focus: false,
        },
        competence1: {
            name: 'competence1',
            label: 'Competence 1 ',
            value: '',
            focus: false,
        },
        competence2: {
            name: 'competence2',
            label: 'Competence 2',
            value: '',
            focus: false,
        },
        competence3: {
            name: 'competence3',
            label: 'Competence 3',
            value: '',
            focus: false,
        },
      }
    }
    
    handleFocus(e) {
      const name = e.target.name;
      const state = Object.assign({}, this.state[name]);
      state.focus = true;
      this.setState({ [name]: state },()=>{console.log(state)});
    }
    
    handleBlur(e) {
      const name = e.target.name;
      const state = Object.assign({}, this.state[name]);
      state.focus = false;
      this.setState({ [name]: state },()=>{console.log(state)});
    }
    
    handleChange(e) {
      const name = e.target.name;
      const state = Object.assign({}, this.state[name]);
      state.value = e.target.value;
      this.setState({ [name]: state },()=>{console.log(state)});
    }
    sendData = () =>{
        const data = {
            langue: this.state.langue.value,
            recruteur: this.state.name.value,
            email: this.state.email.value,
            societe: this.state.company_name.value,
            role: this.state.role.value,
            skill1: this.state.competence1.value,
            skill2: this.state.competence2.value,
            skill3: this.state.competence3.value
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        
        console.log(data)
        fetch(MAIL_API, requestOptions)
        .then(res=>console.log(res.json()))
    }
    render() {
        const {name, email,competence1,competence2, competence3,langue,company_name,role} = this.state;
        return (
                <Container colors={colors} >
                    <Card>
                        <h1>Envoyer un mail de candidature a un recruteur !</h1>
                        <Form>
                            <SelectInput
                                {...langue}
                                onFocus={this.handleFocus.bind(this)}
                                onBlur={this.handleBlur.bind(this)}
                                onChange={this.handleChange.bind(this)}
                            />
                        <TextInput
                            {...name}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)} />
                        <TextInput
                            {...email}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)} />
                        <TextInput
                            {...company_name}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)} />
                        <TextInput
                            {...role}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)} />
                        <TextInput
                            {...competence1}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)} />
                        <TextInput
                            {...competence2}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)} />
                        <TextInput
                            {...competence3}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)} />
                        {/* <TextArea
                            {...message}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)} /> */}
                        <Button onClick={this.sendData}>Send</Button>
                        </Form>
                    </Card>
                </Container>        
      );
    }
}

const colors = {
    primary: "#3aa378ff",
    secondary: "#124",
    accent: "#e72"
}
const inputCSS = css`
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #eee;
    padding: 1ch;
    border-radius: 3px;
    transition: 150ms ease;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
`
const inputFocus = css`
    border-color: #3aa378ff;
`
const label = css`
    position: absolute;
    top: 1ch;
    left: 1ch;
    padding: 0 .5ch;
    pointer-events: none;
    transition: 150ms ease;
    color: #888;
`
const labelFocus = css`
    position: absolute;
    top: 0;
    font-size: 0.9em;
    color: #3aa378ff;
    font-weight: bold;
    transform: translateY(-50%);
    background-color: #fff;
    font-weight: 600;
`
const Container = styled.div`
    background-color: #f8f8f8;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    .card {
        width: 600px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 1px 2px 10px rgba(0,0,0,0.2);
        overflow: hidden;
        margin: 1rem;
        select{
            ${inputCSS}
        }
        h1 {
            margin: 2rem 2rem 0;
            font-size: 1.62rem;
        }

        .form {
            padding: 2rem;
        }

        .button {
            display: block;
            width: 100%;
            background-color: ${props => props.colors.primary};
            border: none;
            border-radius: 3px;
            padding: 1ch;
            font-size: 1rem;
            color: #fff;
            font-family: inherit;
        }

        input {
            ${inputCSS}
        }

        input-focus {
            ${inputFocus}
        }

        label {
            ${label}
        }

        label-focus {
            ${labelFocus}
        }
        .text-input {
            position: relative;
            margin-bottom: 1rem;
            label {
                ${label};
            }
            label.label-focus {
                ${labelFocus};
            }
            input {
                ${inputCSS};
            }
            input.input-focus {
                ${inputFocus};
            }

        }

        .text-area {
            position: relative;
            margin-bottom: 1rem;
            label {
                ${label};
            }
            label.label-focus {
                ${labelFocus};
            }
            textarea {
                ${inputCSS};
                height: 8em;
            }
            textarea.input-focus {
                ${inputFocus};
            }
        }
    }
`
  
export default Sender